<template>

<app-content :loading="is.loading">

	<app-content-head :title="title" :back="$route.meta.back" :readonly="$authorised('org/library/access', 'read')">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew" :disabled="isNew" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body>

		<app-content-box :readonly="$authorised('org/library/access', 'read')">

			<app-input-suggest label="Category" v-model="model.category" :validation="$v.model.category" api="bgg/category" placeholder="Select category" />
			<app-input-toggle label="Enabled" v-model="model.enabled" :validation="$v.model.enabled" />
			<app-input-toggle label="Pinned" v-model="model.pinned" :validation="$v.model.pinned" />
			<app-input-text label="Title" placeholder="Enter title..." v-model="model.name" :validation="$v.model.name" />
			<app-input-image v-model="model.image" :validation="$v.model.image" label="Image" />
			<app-input-colour v-model="model.color" :validation="$v.model.color" label="Color" placeholder="Enter hex" />
			<app-input-text label="URL" placeholder="Enter URL..." v-model="model.url" :validation="$v.model.url" />

		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { required } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			model: {
				id: false,
				enabled: false,
				category: false,
				pinned: false,
				name: '',
				image: '',
				color: '',
				url: ''
			}
		}

	},

	validations: {
		model: {
			category: {
				required
			},
			enabled: {
				required
			},
			pinned: {
				required
			},
			name: {
				required
			},
			image: {
				
			},
			color: {
				required
			},
			url: {
				required
			}
		}
	}

}

</script>

<style scoped>

</style>